<template>
  <section>
    <div class="row">
      <div class="col-xs-6 col-md-6">
        <label for="tax_invoice_status">{{ $t("invoice_status") }}</label
        ><br />
        <Radio
          id="tax_invoice_status"
          :options="taxInvoiceStatusOptions"
          v-model="itemFilter.taxInvoiceStatus"
        />
      </div>
      <div class="col-xs-6 col-md-3">
        <label>{{ $t("date_range") }}</label
        ><br />
        <DatetimeRangePicker
          :startRef="itemFilter.startDate"
          :endRef="itemFilter.endDate"
          :min="8760"
          :shortcuts="false"
          :allowFutureDate="true"
          :showRefreshIcon="false"
          @interval-changed="onIntervalChanged"
          class="datetime-range-picker"
        ></DatetimeRangePicker>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-6 col-md-6">
        <label for="description">{{ $t("description") }}</label
        ><br />
        <input
          type="text"
          class="form-control"
          maxlength="200"
          placeholder="Ex: Mensalidade, Plano Enterprise, etc."
          v-model="itemFilter.description"
          :disabled="busy"
        />
      </div>
      <div class="col-xs-6 col-md-6">
        <label>{{ $t("value") }}</label>
        <div class="row">
          <div class="col-xs-4 col-md-1">{{ $t("range_from") }}:</div>
          <div class="col-xs-8 col-md-4">
            <InputMoney
              id="min_value"
              v-model="itemFilter.minValue"
              :disabled="busy"
            />
          </div>
          <div class="col-xs-4 col-md-1">{{ $t("range_to") }}:</div>
          <div class="col-xs-8 col-md-4">
            <InputMoney
              id="max_value"
              v-model="itemFilter.maxValue"
              :disabled="busy"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <Spin v-if="!dataLoaded" />
      <div class="col-xs-12" v-if="dataLoaded">
        <SearchableTable
          class="searchable-table"
          :items="itemsFiltered"
          :pagination="true"
          :searchEnabled="false"
          :maxResult="itemsFiltered.length"
          :fields="fields"
          :commands="commands"
          @command="onCommand"
        />
      </div>
    </div>

    <Modal
      :open="selectedTaxInvoice != null"
      @update:open="selectedTaxInvoice = $event ? selectedTaxInvoice : null"
      :title="
        $t('tax_invoice') +
          ' ' +
          currentTaxInvoice.series +
          ' / ' +
          currentTaxInvoice.serial_number
      "
      iconClass="fa fa-file-text-o"
      :footer="false"
    >
      <template #content>
        <TaxInvoiceTable :currentTaxInvoice="currentTaxInvoice" />
      </template>
    </Modal>

    <Modal
      :open="selectedInvoice != null"
      @update:open="selectedInvoice = $event ? selectedInvoice : null"
      :title="$t('invoice') + ' #' + currentInvoice.id"
      iconClass="fa fa-money"
      :footer="false"
    >
      <template #content>
        <InvoiceTable :currentInvoice="currentInvoice" />
      </template>
    </Modal>

    <Modal
      :open="printTaxInvoice != null"
      @update:open="printTaxInvoice = $event ? printTaxInvoice : null"
      :title="printTaxInvoiceTitle"
      iconClass="fa fa-print"
      :footer="false"
    >
      <template #content>
        <PrintTaxInvoice
          :urlNFSe="urlNFSe"
          :printTaxInvoiceDetails="printTaxInvoiceDetails"
        />
      </template>
    </Modal>
  </section>
</template>

<script>
import DashboardEditPickerBase from "@/components/registration/dashboard-edit-picker-base.vue";
import SearchableTable from "@/components/searchable-table.vue";
import DatetimeRangePicker from "@/components/datetime-range-picker.vue";
import InputMoney from "@/components/input-money.vue";
import Radio from "@/components/base/radio.vue";
import BillingService from "@/services/billing.js";
import Modal from "@/components/widgets/modal.vue";
import TaxInvoiceTable from "./tax-invoice-table.vue";
import InvoiceTable from "./invoice-table.vue";
import PrintTaxInvoice from "./print-tax-invoice.vue";
import Spin from "@/components/spin";

export default {
  name: "UserBillingTaxInvoicePicker",
  components: {
    SearchableTable,
    DatetimeRangePicker,
    InputMoney,
    Radio,
    Modal,
    PrintTaxInvoice,
    TaxInvoiceTable,
    InvoiceTable,
    Spin
  },
  extends: DashboardEditPickerBase,
  props: {
    formPicker: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    let self = this;
    let cmds = {
      title: "loading",
      taxInvoiceStatusOptions: [
        { value: "all", description: this.$t("invoice_status_all") },
        { value: "emitted", description: this.$t("invoice_status_emitted") },
        {
          value: "cancelled",
          description: this.$t("invoice_status_cancelled")
        },
        {
          value: "invalidated",
          description: this.$t("invoice_status_invalidated")
        }
      ],
      items: [],
      fields: [
        {
          name: "id",
          title: "id",
          visible: false
        },
        {
          name: "invoice_id",
          title: "invoice_id",
          visible: false
        },
        {
          name: "date",
          title: "date",
          format: (v) => {
            try {
              return self.$dt.format(v).split(" ")[0];
            } catch {
              return "";
            }
          }
        },
        {
          name: "series",
          title: "series"
        },
        {
          name: "serial_number",
          title: "invoice_serial_number"
        },
        {
          name: "status",
          title: "invoice_status"
        },
        {
          name: "description",
          title: "description"
        },
        {
          name: "value",
          title: "value",
          style: { "text-align": "right" },
          format: (v) => {
            return self.$utils.formatMoney(v);
          }
        }
      ],
      commands: [
        {
          name: "tax_invoice",
          title: "tax_invoice",
          icon: "fa fa-file-text-o"
        },
        {
          name: "invoice",
          title: "invoice",
          icon: "fa fa-money"
        },
        {
          name: "print",
          title(item) {
            if (item.url) {
              return self.$t("print_tax_invoice");
            } else {
              return self.$t("tax_invoice_has_no_print");
            }
          },
          icon: "fa fa-print",
          enabled(item) {
            return item.url != "";
          }
        }
      ],
      itemFilter: {
        description: "",
        startDate: moment().add(-1, "year"),
        endDate: moment(),
        taxInvoiceStatus: "all",
        minValue: null,
        maxValue: null
      },
      busy: false,
      urlNFSe: "http://nfse.campinas.sp.gov.br/NotaFiscal/index.php",
      dataLoaded: false,
      selectedInvoice: null,
      selectedTaxInvoice: null,
      printTaxInvoice: null
    };
    return cmds;
  },
  computed: {
    convertedStatus() {
      switch (this.itemFilter.taxInvoiceStatus) {
        case "emitted":
          return "Emitida";
        case "invalidated":
          return "Invalidada";
        case "cancelled":
          return "Cancelada";
        default:
          return null;
      }
    },
    currentInvoice() {
      return this.selectedInvoice != null ? this.selectedInvoice : {};
    },
    currentTaxInvoice() {
      return this.selectedTaxInvoice != null ? this.selectedTaxInvoice : {};
    },
    currentTaxInvoiceTitle() {
      if (!this.currentTaxInvoice) return;
      if (this.currentTaxInvoice?.serial_number) {
        return (
          this.$t("tax_invoice") +
          " " +
          this.currentTaxInvoice.series +
          " / " +
          this.currentTaxInvoice.serial_number
        );
      } else {
        return (
          this.$t("tax_invoice") +
          " " +
          this.currentTaxInvoice.series +
          " / " +
          this.$t("no_serial_number")
        );
      }
    },
    printTaxInvoiceDetails() {
      if (!this.printTaxInvoice?.url) return [];
      return this.gerUrlNFSeInfo(this.printTaxInvoice.url);
      //let str = "Site http://nfse.campinas.sp.gov.br/NotaFiscal/index.php ->Opção Verificar Autenticidade <br>CNPJ: <b>29275970000181</b><br>NFSe: <b>00000131</b><br>Inscrição municipal: <b>004678656</b><br>Código de verificação: <b>ad6ea09a</b><br>";
      //return this.gerUrlNFSeInfo(str);
    },
    printTaxInvoiceTitle() {
      if (!this.printTaxInvoice) return;
      if (this.printTaxInvoice?.serial_number) {
        return (
          this.$t("tax_invoice") +
          " " +
          this.printTaxInvoice.series +
          " / " +
          this.printTaxInvoice.serial_number
        );
      } else {
        return (
          this.$t("tax_invoice") +
          " " +
          this.printTaxInvoice.series +
          " / " +
          this.$t("no_serial_number")
        );
      }
    },
    itemsFiltered() {
      if (!Array.isArray(this.items)) return [];

      let description = this.itemFilter.description.trim().toUpperCase();
      let startDate = this.itemFilter.startDate;
      let endDate = this.itemFilter.endDate;
      let minValue = this.itemFilter.minValue;
      let maxValue = this.itemFilter.maxValue;
      let convertedStatus = this.convertedStatus;

      return this.items.filter((item) => {
        // Description
        if (description.trim() != "" && item.description) {
          if (!item.description.toUpperCase().includes(description))
            return false;
        }

        // Period
        let momentDate = moment(item.date);

        if (momentDate < startDate || momentDate > endDate) return false;

        // Status
        if (convertedStatus && item.status != convertedStatus) return false;

        // Value Range
        if (typeof minValue == "number" && item.value < minValue) return false;
        if (
          typeof maxValue == "number" &&
          maxValue >= minValue &&
          item.value > maxValue
        )
          return false;

        return true;
      });
    }
  },
  watch: {},
  methods: {
    invoice(event) {
      let self = this;
      this.service.invoices(null, event.target.invoice_id).then((result) => {
        if (result) {
          self.selectedInvoice = result;
        } else {
          self.$swal({
            title: self.$t("error"),
            text: self.$t("not_found"),
            icon: "error"
          });
        }
      });
    },
    tax_invoice(event) {
      this.selectedTaxInvoice = event.target;
    },
    print(event) {
      this.printTaxInvoice = event.target;
    },
    fetchItems() {
      this.service.tax_invoices().then((result) => {
        this.$set(this, "items", result.reverse());
        this.$set(this, "dataLoaded", true);
      });
    },
    gerUrlNFSeInfo(url) {
      return url.match(/<b>(.*?)<\/b>/g).map(function(val) {
        return val.replace(/<\/?b>/g, "");
      });
    },
    onIntervalChanged(e) {
      this.itemFilter.startDate = e.startDate;
      this.itemFilter.endDate = e.endDate;
    }
  },
  beforeCreate() {
    this.service = new BillingService();
  },
  mounted() {}
};
</script>

<style scoped>
.searchable-table {
  margin-top: 30px;
}
.datetime-range-picker {
  max-width: 100%;
}
</style>
