<template>
  <div class="row">
    <div class="col-md-12">
      <h4>Siga as instruções abaixo para visualizar o espelho da Nota Fiscal de número <strong>{{ printTaxInvoiceDetails[1] }}</strong></h4>
      <ol>
        <li>Acesse o endereço: <a :href="urlNFSe" target="_blank">{{ urlNFSe }}</a></li>
        <li>Selecione a opção <strong>Verificar Autenticidade</strong> e preencha os dados conforme abaixo:
          <ul>
            <li><strong>CNPJ:</strong> {{ printTaxInvoiceDetails[0] }}</li>
            <li><strong>NFSe:</strong> {{ printTaxInvoiceDetails[1] }}</li>
            <li><strong>Inscrição Municipal:</strong> {{ printTaxInvoiceDetails[1] }}</li>
            <li><strong>Código de Verificação:</strong> {{ printTaxInvoiceDetails[2] }}</li>
          </ul>
        </li>
        <li>Em <strong>Reproduza o código</strong> preencha o código captcha conforme está escrito.</li>
        <li>Clique no botão <strong>Verificar</strong>.</li>
      </ol>
    </div>
  </div>
</template>

<script>
export default {
  name: "PrintTaxInvoice",
  props: {
    urlNFSe: {
      type: String,
      required: true
    },
    printTaxInvoiceDetails: {
      type: Array,
      required: true
    }
  }
};
</script>