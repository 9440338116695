<template>
  <section>
    <div class="pricing-table" v-if="isReady">
      <div class="pricing-table-header">
        <div class="row">
          <div class="col-md-6 hide-small">
            <div class="princing-table-head">
              <span>
                {{ $t("resources") }}
              </span>
            </div>
          </div>
          <div class="col-md-3">
            <div class="pricing-table-cell pricing-table-plan-info">
              <div class="pricing-table-cell-title">
                {{ $t("current_plan") }}
              </div>
              <div class="pricing-table-cell-subtitle">
                {{ resultItem("name", 0) }}
              </div>
              <span class="price">
                <span class="sign">{{ current_sign }}</span>
                <span class="currency">{{
                  currency("plan_value_fee", 0)
                }}</span>
                <span class="cent">,{{ cents("plan_value_fee", 0) }}</span>
                <span class="month">/{{ $t("month") }}</span>
              </span>
            </div>
          </div>
          <div class="col-md-3">
            <div class="pricing-table-cell pricing-table-plan-info">
              <div class="pricing-table-cell-title">
                {{ $t("simulated_plan") }}
                <i class="fa fa-refresh fa-spin" v-if="locked"></i>
                <i
                  class="fa fa-close"
                  :title="$t('reset')"
                  v-else
                  v-on:click.stop.prevent="resetFeatureList"
                ></i>
              </div>
              <div class="pricing-table-cell-subtitle" data-testid="plan_type">
                {{ resultItem("name", 1) }}
              </div>
              <span class="price text-green">
                <span class="sign" data-testid="sign">{{ current_sign }}</span>
                <span class="currency" data-testid="currency">{{
                  currency("plan_value_fee", 1)
                }}</span>
                <span class="cent" data-testid="cent"
                  >,{{ cents("plan_value_fee", 1) }}</span
                >
                <span class="month" data-testid="month"
                  >/{{ $t("month") }}</span
                >
              </span>
            </div>
          </div>
        </div>
      </div>

      <div class="pricing-table-body">
        <div class="row" v-for="(item, ix) in featureList" v-bind:key="ix">
          <div class="col-md-6 col-xs-12">
            <div class="h4 visible-xs-block" v-html="$tc(item.text)">:</div>
            <div
              class="pricing-table-cell pricing-table-decorated-cell hidden-xs"
              data-toggle="tooltip"
              data-placement="auto"
              :title="$t('hints.' + item.hint)"
              v-html="$tc(item.text)"
            ></div>
          </div>
          <div class="col-md-3 col-xs-6">
            <div class="pricing-table-cell pricing-table-decorated-cell">
              <div v-if="item.yesno_question">
                <input
                  type="checkbox"
                  :checked="item.quota.value"
                  class="icon-checkbox"
                  @click.prevent
                />
              </div>
              <div v-else>
                {{ item.quota.value }}
              </div>
            </div>
          </div>

          <div class="col-md-3 col-xs-6">
            <div
              class="pricing-table-cell pricing-table-decorated-cell pricing-table-decorated-input"
              v-if="item.yesno_question"
            >
              <input
                :data-testid="item.name"
                type="checkbox"
                v-model="item.usage.value"
                class="icon-checkbox"
              />
            </div>
            <div
              class="pricing-table-cell pricing-table-decorated-cell pricing-table-decorated-input"
              v-else
            >
              <input
                class="input-number"
                type="number"
                :data-testid="item.name"
                min="0"
                inc="1"
                v-model="item.usage.value"
                v-bind:disabled="busy"
                v-on:click.stop.prevent="onClickInput(item, $event)"
              />
            </div>
          </div>
          <div class="divisor col-xs-12 visible-xs-block"></div>
        </div>

        <hr />

        <div
          class="row"
          v-for="(item_name, ix) in sequence"
          v-bind:key="(ix + 1) * 1000"
        >
          <div class="col-md-6">
            <div class="pricing-table-cell pricing-table-cell-result">
              {{ $tc(item_name) }}
            </div>
          </div>
          <div class="col-md-3">
            <div class="pricing-table-cell pricing-table-cell-result">
              <div>{{ resultItem(item_name, 0) }}</div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="pricing-table-cell pricing-table-cell-result">
              <div :data-testid="item_name">{{ resultItem(item_name, 1) }}</div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <div class="contract-info-box">
              <div
                v-if="!editing"
                v-bind:title="$t('contract')"
                v-on:click.stop.prevent="onClickEdit"
              >
                <div class="edit-icon" v-if="canEdit">
                  <i class="fa fa-pencil"></i>
                </div>
                <div class="h4 text-center">{{ $t("current_contract") }}</div>
                <div class="h4 text-center" style="padding:0 40px;">
                  <div>
                    <small>
                      <b> {{ $t("start") }}: </b>
                    </small>
                    <small v-if="contract && contract.start_datetime">{{
                      $dt.format(contract.start_datetime)
                    }}</small>
                  </div>
                  <div v-if="contract && contract.name">
                    <small>
                      <b> {{ $t("name") }}: </b>
                    </small>
                    <small>{{ (contract && contract.name) || "" }}</small> -
                    <small>{{
                      (contract && contract.description) || ""
                    }}</small>
                  </div>
                </div>
              </div>
              <div v-else>
                <FormContract
                  v-on:cancel="editing = false"
                  v-on:save="onSave"
                />
              </div>
            </div>
          </div>

          <div class="col-md-6">
            <div class="col-md-12 text-center">
              <div class="buy">
                <a
                  class="buy"
                  v-bind:class="{ disabled: !terms }"
                  data-testid="upgrade-plan"
                  href="#"
                  v-on:click="onUpgradeClicked"
                  >{{ $t("upgrade_plan") }}
                </a>
              </div>
            </div>
            <div class="col-md-12 text-center">
              <Terms v-model="terms" />
            </div>
            <div class="col-md-12 text-center">
              <div v-if="featureResult && featureResult.length">
                <div class="text-left">
                  <label for=""
                    >{{ $t("contract_resources") }} ({{
                      (result && result.name[1]) || ""
                    }}):
                  </label>
                  <div
                    class="small no-wrap"
                    v-for="(item, i) in summary"
                    v-bind:key="(i + 1) * 2000"
                    v-html="item"
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import ContractService from "@/services/contract.js";
import MixinAlert from "@/project/mixin-alert.js";
import FormContract from "@/components/form-contract.vue";
import Terms from "@/components/terms.vue";
import { debounceTime } from "@/utils";
import isEqual from "lodash/isEqual";

export default {
  name: "UserPlanUpgradeA",
  mixins: [MixinAlert],
  components: {
    FormContract,
    Terms
  },
  props: {},
  data() {
    return {
      busy: false,
      terms: false,
      editing: false,
      featureList: [],
      featureResult: [],
      quotation: null,
      sequence: [
        "plan_value_fee",
        "balance_amount",
        "minimum_value",
        "base_value",
        "amount_to_be_paid"
      ],
      result: null,
      locked: false
    };
  },
  computed: {
    user() {
      return this.$store.getters["user/loggedUser"] || null;
    },
    isDemo() {
      return (
        (this.user && this.user.contract && this.user.contract.is_demo) || false
      );
    },
    isRoot() {
      return (this.user && this.user.is_root) || false;
    },
    contract() {
      return this.$store.getters["user/contract"] || null;
    },
    current_sign() {
      return this.$root.config.currency_sign || "R$";
    },
    resources() {
      return (
        (this.contract &&
          this.contract.billing &&
          this.contract.billing.resources) ||
        null
      );
    },
    plan() {
      return (
        (this.contract &&
          this.contract.billing &&
          this.contract.billing.plan) ||
        null
      );
    },
    isReady() {
      return this.resources != null;
    },
    payload() {
      if (this.isReady) {
        let payload = {};
        for (var i in this.featureList) {
          let item = this.featureList[i];
          if (item.yesno_question) {
            payload[item.quota.source] = item.usage.value ? true : false;
          } else {
            payload[item.quota.source] = parseInt(item.usage.value || 0, 10);
          }
        }
        return payload;
      }
      return null;
    },
    billing() {
      let contract = this.contract || {};
      return (contract && contract.billing) || null;
    },
    isValid() {
      let self = this;
      if (!(self.isRoot || self.isDemo)) return false;
      if (!self.terms || self.busy || self.dirty) return false;
      return true;
    },
    canEdit() {
      return !(this.isDemo || this.editing);
    },
    summary() {
      let lst = [];
      for (var i in this.featureResult) {
        let item = this.featureResult[i];
        if (item.yesno_question) {
          if (item.quotation) {
            lst.push(this.$tc(item.text));
          }
        } else {
          lst.push(this.$tc(item.text) + ":" + item.quotation);
        }
      }
      // as html
      lst = lst.map((i, ix) => "&nbsp;" + i + (ix < lst.length - 1 ? "," : ""));
      return lst;
    }
  },
  watch: {
    isReady(n) {
      if (n) {
        this.resetFeatureList();
      }
    },
    payload(n, o) {
      if (n && Object.keys(o).length && this.isReady && !isEqual(n, o)) {
        let self = this;
        self.locked = true;
        this.fetchPrice();
      }
    },
    busy(n) {
      this.$emit("loading", n);
    }
  },
  methods: {
    currency(name, ix) {
      return this.$utils.currency(this.resultItem(name, ix) || 0);
    },
    cents(name, ix) {
      return this.$utils.cents(this.resultItem(name, ix) || 0);
    },
    resultItem(name, ix) {
      return this.result && "name" in this.result ? this.result[name][ix] : "";
    },
    resetFeatureList() {
      let featureList = JSON.parse(JSON.stringify(this.resources || []));
      for (var i in featureList) {
        featureList[i].usage.value = featureList[i].quota.value;
      }
      this.$set(this, "featureList", featureList);
      if (this.isReady) {
        this.busy = false;
        this.updateResult(this.plan, 0);
        this.updateResult(this.plan, 1);
      }
      this.$nextTick(() => {
        $('[data-toggle="tooltip"]').tooltip({
          html: true,
          delay: { show: 500, hide: 500 }
        });
      });
    },
    updateResult(plan, ix) {
      // ix=0 update original plan
      // ix=1 update desired plan
      let result = this.result;
      if (!result) {
        result = { name: ["-", "-"] };
        for (var i in this.sequence) {
          result[this.sequence[i]] = ["", ""]; // contract, simulation, quotation
        }
      }
      for (var i in result) {
        if (plan && i in plan) {
          result[i][ix] = plan[i];
        } else {
          result[i][ix] = "-";
        }
      }
      this.$set(this, "result", result);
    },
    validateTablePrice(entry) {
      let self = this;
      if (this.plan && entry.quotation.plan != this.plan.name) {
        this.$nextTick(() => {
          self
            .$swal({
              title: self.$t("plan_update"),
              text: self.$t("plan_update_warning"),
              buttons: [
                self.$t("cancel"),
                self.$t("do_you_want_to_update_your_plan")
              ]
            })
            .then(function(ok) {
              if (ok) {
                self.$emit("requestPlanChange", "UserPlanUpgradeB");
                // let contract = JSON.parse(JSON.stringify(self.contract));
                // contract.pricing_by_data_range = true;
                // self.$store.commit("user/SET_CONTRACT", contract);
              }
            });
        });
        return false;
      }
      return true;
    },
    fetchPrice: debounceTime(2000, function() {
      let self = this;
      if (self.busy) return;
      self.locked = false;
      let payload = self.payload;
      if (payload) {
        try {
          self.busy = true;
          self.service
            .fetchPrice(payload)
            .then((response) => {
              try {
                let entry = JSON.parse(JSON.stringify(response));
                if (!this.validateTablePrice(entry)) return;
                let quotation = entry.quotation;
                if ("plan" in quotation) {
                  let featureList = JSON.parse(
                    JSON.stringify(self.featureList)
                  );
                  for (var i in featureList) {
                    if (featureList[i].quota.source in entry) {
                      featureList[i].quotation =
                        entry[featureList[i].quota.source];
                    }
                  }
                  this.$set(this, "featureResult", featureList);
                  quotation.name = quotation.plan;
                  delete quotation.plan;
                  this.updateResult(quotation, 1);
                }
              } catch (error) {}
            })
            .catch((err) => {
              self.$swal({
                title: self.$t("invalid_resources"),
                text: err || self.$t("unknown_error_while_executing_request"),
                icon: "error"
              });
            })
            .then(() => (self.busy = false));
        } catch (error) {
          self.busy = false;
        }
      }
    }),
    upgrade() {
      let self = this;
      let payload = self.payload;
      if (payload) {
        // demo need to create the first contract
        if (!this.isDemo) {
          payload.id = (self.contract && self.contract.id) || "";
          payload.etag = (self.contract && self.contract.etag) || "";
        }
        self.busy = true;
        self.service
          .updateContract(payload)
          .then((response) => {
            self
              .$swal({
                title: self.$t("completed"),
                text: self.$t("your_plan_was_updated"),
                icon: "success"
              })
              .catch((err) => {
                self.$swal({
                  title: self.$t("item_could_not_be_saved"),
                  text: self.$t("your_plan_could_not_be_updated"),
                  icon: "error"
                });
              })
              .then((confirm) => {
                document.location.reload();
              });
          })
          .catch((err) => {
            self.$swal({
              title: self.$t("item_could_not_be_saved"),
              text: self.$t("your_plan_could_not_be_updated"),
              icon: "error"
            });
          })
          .then(() => (self.busy = false));
      }
    },
    onUpgradeClicked() {
      let self = this;
      if (!self.terms || self.busy) return;
      if (!(this.isRoot || this.isDemo)) {
        return;
      }
      self
        .$swal({
          title: self.$t("are_you_sure"),
          text: self.$t("do_you_really_want_to_upgrade_your_current_plan"),
          icon: "success",
          buttons: [self.$t("cancel"), self.$t("yes_im_sure")]
        })
        .then(function(isConfirm) {
          if (isConfirm) {
            self.upgrade();
          }
        });
    },
    onClickInput(item, event) {
      try {
        if (event.target.value == 0) event.target.value = "";
      } catch (error) {}
    },
    onSave(contract) {
      let self = this;
      self.busy = true;
      setTimeout(function() {
        self.editing = false;
        self.busy = false;
      }, 1000);
    },
    onClickEdit() {
      if (!this.canEdit) {
        return;
      }
      this.editing = true;
    }
  },
  beforeCreate() {
    this._timer = null;
  },
  beforeDestroy() {
    if (this._timer) {
      clearTimeout(this._timer);
      this._timer = null;
    }
  },
  created() {
    this.service = new ContractService();
    this.busy = true;
    this.resetFeatureList();
  }
};
</script>

<style scoped>
.pricing-table {
  margin: 0 0 0 0;
  border-radius: 10px;
  overflow: hidden;
}

.pricing-table-header {
  background-color: #f6f6f6;
}

.pricing-table-body {
  background-color: whitesmoke;
  padding: 0 10px 20px 10px;
  border-radius: 5px;
  min-height: 300px;
}

.princing-table-head {
  font-family: "Lato", sans-serif;
  font-size: 42px;
  font-weight: 300;
  letter-spacing: -2px;
  /* line-height: 2em; */
  padding: 25px 0;
  vertical-align: middle;
  text-align: center;
  color: #737373;
  background-color: #e0dfdf;
}

.pricing-table-cell {
  text-align: center;
  font-family: "Lato", sans-serif;
  font-size: 18px;
  padding: 10px 2px;
  margin: 2px;
  white-space: nowrap;
}

.pricing-table-plan-info {
  padding: 0;
}

.pricing-table-cell-title {
  padding-top: 6px;
  margin-bottom: -6px;
  color: #989898;
}
.pricing-table-cell-subtitle {
  color: gray;
  font-size: 16pt;
}

.pricing-table-decorated-cell {
  transition: all 0.3s ease-in-out 0s;
  background-color: white;
  color: #737373;
  border-left: 5px solid transparent;
  border-bottom: 1px solid #f7f7f7;
}

.pricing-table-decorated-cell:hover {
  transition: all 0.3s ease-in-out 0s;
  background-color: #e4e4e4;
  border-left: 5px solid #2ecc71;
}

.pricing-table-decorated-input:hover {
  cursor: pointer;
}

.pricing-table-cell-result {
  min-height: 32px;
  background-color: white;
  color: #6f8896;
  font-size: 15px;
  padding: 6px 2px;
  text-align: center;
}

.icon-checkbox {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
  width: 100%;
}

.icon-checkbox:focus {
  outline: none;
}

.icon-checkbox::before {
  content: "\2718";
  color: #ff0000;
  font-size: 14pt;
  font-weight: bold;
  margin: 0 auto;
  display: block;
  width: 14pt;
}

.icon-checkbox:checked::before {
  content: "\2713";
  color: #009e2c;
}

input.input-number {
  width: 100%;
  border: 0;
  padding: 0;
  margin: 0;
  text-align: center;
  background-color: transparent;
}

input.input-number:hover {
  cursor: pointer;
  opacity: 0.7;
  color: #2d6a8e;
  font-weight: bold;
  background-color: transparent;
}

input.input-number:focus {
  outline: 0;
  color: #000;
  font-weight: bold;
  background-color: transparent;
}

.price {
  display: block;
  padding: 0;
}

.price .sign {
  display: inline-block;
  font-family: "Lato", sans-serif;
  font-size: 24px;
  font-weight: 400;
  vertical-align: middle;
  padding: 5px;
}
.price .currency {
  font-family: "Lato", sans-serif;
  font-size: 42px;
  font-weight: 300;
  letter-spacing: -2px;
  line-height: 60px;
  padding: 0;
  vertical-align: middle;
}
.price .cent {
  display: inline-block;
  font-family: "Lato", sans-serif;
  font-size: 18px;
  font-weight: 400;
  vertical-align: text-bottom;
}
.price .month {
  font-family: "Lato", sans-serif;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0px;
  vertical-align: text-top;
}

div.buy {
  margin-top: 40px;
}

a.buy {
  border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
  -o-border-radius: 50px;
  -webkit-border-radius: 50px;
  display: inline-block;
  font-family: "Lato", sans-serif;
  font-size: 18px;
  outline: medium none;
  padding: 12px 30px;
  text-decoration: none;
  text-transform: uppercase;
  border: 1px solid #2ecc71;
  background-color: #2ecc71;
  color: #fff;
}

a.buy.disabled {
  border: 1px solid #d9e6df;
  background-color: #90e8b6;
  color: #949494;
}

a.buy.disabled:hover {
  cursor: not-allowed;
}

.contract-info-box {
  position: relative;
  margin-top: 20px;
  padding: 10px;
  border-radius: 10px;
  background-color: #e8e8e8;
  opacity: 0.8;
}

.contract-info-box:hover {
  cursor: pointer;
  background-color: #f8f8f8;
  color: #2d6a8e;
  opacity: 1;
}

.edit-icon {
  position: absolute;
  right: 10px;
  top: 10px;
  visibility: hidden;
}

.contract-info-box:hover .edit-icon {
  visibility: visible;
}
.divisor {
  margin-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #90e8b6;
}

i.fa-close:hover {
  cursor: pointer;
  color: #2d6a8e;
}
label {
  margin-bottom: 0px;
}
.no-wrap {
  white-space: nowrap;
  display: inline-block;
}
</style>
