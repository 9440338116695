<template>
  <section>
    <div class="container">
      <UserPlanPanel v-if="showMyPlan" />
      <InfoBox v-else preset="unauthorized" />
    </div>
  </section>
</template>

<script>
import InfoBox from "@/components/info-box.vue";

import UserPlanPanel from "@/components/user-plan/user-plan-panel.vue";
export default {
  name: "DashboardUserPlan",
  components: {
    InfoBox,
    UserPlanPanel
  },
  computed: {
    user() {
      return this.$store.getters["user/loggedUser"] || null;
    },
    isRoot() {
      return (this.user && this.user.is_root) || false;
    },
    isDemo() {
      return (
        (this.user && this.user.contract && this.user.contract.is_demo) || false
      );
    },
    showMyPlan() {
      return (
        this.$root.config &&
        this.$root.config.plan_upgrade &&
        (this.isRoot || (this.isDemo && !this.doesUserOwnAContract))
      );
    },
    doesUserOwnAContract() {
      // returns true if user own any contract
      let user_id = (this.user && this.user.id) || "";
      if (user_id) {
        let contract_list = this.contract_list;
        for (var i in contract_list) {
          if (contract_list[i].owner_id == user_id) {
            return true;
          }
        }
      }
      return false;
    },
    contract_list() {
      return (this.user && this.user.is_member_contracts) || [];
    }
  },
  mounted() {
    this.$root.$emit("controlSidebar:setContent", null);
  }
};
</script>

<style scoped>
.panel-content {
  min-height: 800px;
}
</style>
