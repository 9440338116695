<template>
  <table class="table table-bordered table-striped">
    <tbody>
      <tr>
        <td><strong>{{ $tc("date") }}</strong></td>
        <td>{{ currentTaxInvoiceDate }}</td>
      </tr>
      <tr>
        <td><strong>{{ $tc("invoice_status") }}</strong></td>
        <td>{{ currentTaxInvoice.status }}</td>
      </tr>
      <tr>
        <td><strong>{{ $tc("series") + ' / ' + $tc("invoice_serial_number") }}</strong></td>
        <td>{{ getSeries(currentTaxInvoice) }}</td>
      </tr>
      <tr>
        <td><strong>{{ $tc("description") }}</strong></td>
        <td>{{ currentTaxInvoice.description }}</td>
      </tr>
      <tr>
        <td><strong>{{ $tc("value") }}</strong></td>
        <td>{{ $utils.formatMoney(currentTaxInvoice.value) }}</td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  name: "TaxInvoiceTable",
  props: {
    currentTaxInvoice: {
      type: Object,
      required: true
    }
  },
  computed: {
    currentTaxInvoiceDate() {
      try {
        return this.$dt.format(this.currentTaxInvoice.date).split(' ')[0];
      } catch {
        return "";
      }
    }
  },
  methods: {
    getSeries(currentTaxInvoice) {
      if (currentTaxInvoice.serial_number) {
        return currentTaxInvoice.series + " / " + currentTaxInvoice.serial_number;
      } else {
        return currentTaxInvoice.series + " / (" + this.$t("no_serial_number") + ")";
      }
    }
  }
};
</script>