<template>
  <StatisticsBox :title="$t('usage')">
    <template #table>
      <table class="table table-bordered table-striped">
        <thead>
          <tr>
            <th>{{ $t("resource") }}</th>
            <th class="text-center">{{ $t("amount") }}</th>
            <th class="text-center">{{ $t("limit") }}</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(resource, index) in resources"
            :key="index"
            :class="{ group: resource.isGroup, 'group-item': resource.group }"
            @click="toggleResourceGroup(resource.name)"
            :title="getResourceTitle(resource)"
          >
            <template v-if="resource.group ? isOpen(resource.group) : true">
              <td
                key="name"
                :class="{
                  'resource-name': resource.isGroup || !resource.group
                }"
              >
                <i
                  v-if="resource.isGroup"
                  :class="[
                    'fa',
                    isOpen(resource.name)
                      ? 'fa-chevron-down'
                      : 'fa-chevron-right'
                  ]"
                ></i>
                <Icon class="icon" v-if="resource.icon" :name="resource.icon" />
                {{ $tc(resource.name, 2) }}
                <span
                  v-if="resource.isGroup && groups[resource.name].asterisk"
                  class="text-warning"
                  >*</span
                >
              </td>
              <td class="text-center" key="amount">{{ resource.amount }}</td>
              <td class="text-center" key="limit">
                {{ $t(resource.limit.toString()) }}
              </td>
            </template>
          </tr>
        </tbody>
      </table>
      <h6 style="margin: 2em 1.5em 1em;">
        <span class="text-warning">*</span>
        {{ $t("device_means_connectors_text") }}
      </h6>
    </template>
  </StatisticsBox>
</template>

<script>
import StatisticsBox from "@/components/statistics/statistics-box";
import Icon from "@/components/icons/icon.vue";
import { mapGetters } from "vuex";
import omit from "lodash/omit";

export default {
  name: "ResourcesUsageTable",
  components: {
    StatisticsBox,
    Icon
  },
  data() {
    return {
      groups: {
        device: { open: false, asterisk: true },
        data: { open: false }
      }
    };
  },
  computed: {
    resources() {
      let resources = [];
      if (this.contractUsage) {
        resources = [
          {
            name: "device",
            amount: this.sumResources(
              "devices_hi_count",
              "devices_third_party_count"
            ),
            limit: this.getLimitFor("device"),
            isGroup: true,
            icon: this.getIconFor("device")
          },
          {
            name: "data",
            amount: this.sumResources(
              "data_history_enabled_count",
              "data_history_disabled_count"
            ),
            limit: this.getLimitFor("data"),
            isGroup: true,
            icon: this.getIconFor("data")
          }
        ];

        Object.entries(this.omitResources(this.contractUsage)).forEach(
          (entry) => {
            let item = {
              name: entry[0],
              amount: entry[1],
              limit: this.getLimitFor(entry[0]),
              icon: this.getIconFor(entry[0])
            };
            let group = Object.keys(this.groups).find((name) =>
              item.name.includes(name)
            );
            if (group) {
              item.group = group;
            }
            resources.push(item);
          }
        );
        resources.sort(this.compareResources);
      }
      return resources;
    },
    ...mapGetters("user", ["contractUsage", "contract"])
  },
  methods: {
    getLimitFor(resource) {
      let limitMap = {
        data: "maximum_data",
        data_history_enabled_count: "maximum_histories",
        data_history_disabled_count: null,
        data_disabled_count: null,
        device: null,
        devices_disabled_count: null,
        devices_enabled_count: null,
        devices_third_party_count: "maximum_third_party_connectors",
        alarms: "maximum_alarms",
        alarms_history_disabled_count: null,
        alarms_history_enabled_count: null,
        alarms_disabled_count: null
      };

      let limit = "unlimited";

      if (this.contract && limitMap[resource] in this.contract) {
        limit = this.contract[limitMap[resource]];
        if (limit > 1000000) limit = "unlimited";
      } else if (typeof limitMap[resource] != "undefined") {
        limit = "-";
      }

      return limit;
    },
    getIconFor(resource) {
      let iconMap = {
        device: "portal-icon device[solid]",
        data: "glyphicon glyphicon-stats",
        alarms_count: "fa fa-bell",
        connectors_model_count: "fa fa-plug",
        users_count: "fa fa-user",
        user_groups_count: "fa fa-users",
        process_areas_count: "fa fa-tag",
        screens_count: "fa fa-desktop"
      };

      return iconMap[resource] ?? "";
    },
    sumResources(...resources) {
      return resources.reduce(
        (sum, resource) => sum + this.contractUsage[resource],
        0
      );
    },
    compareResources({ name: first }, { name: second }) {
      let priorityOrder = [
        "device",
        "data",
        "alarm",
        "connector",
        "users_count",
        "group"
      ];

      let firstIndex = priorityOrder.findIndex((resource) =>
        first.includes(resource)
      );
      let secondIndex = priorityOrder.findIndex((resource) =>
        second.includes(resource)
      );

      firstIndex = firstIndex == -1 ? 9999 : firstIndex;
      secondIndex = secondIndex == -1 ? 9999 : secondIndex;

      return firstIndex - secondIndex;
    },
    omitResources(resources) {
      return omit(resources, [
        "alarms_disabled_count",
        "data_disabled_count",
        "devices_disabled_count",
        "devices_enabled_count"
      ]);
    },
    isOpen(resource) {
      return this.groups[resource].open;
    },
    toggleResourceGroup(resource) {
      if (this.groups[resource])
        this.groups[resource].open = !this.groups[resource].open;
    },
    getResourceTitle(resource) {
      if (resource.isGroup) {
        return this.isOpen(resource.name)
          ? this.$t("collapse_details")
          : this.$t("expand_details");
      }
      return "";
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep .table tbody td {
  vertical-align: middle;
}

::v-deep tbody td[rowspan],
tbody td[colspan] {
  width: 1%;
  white-space: nowrap;
}

::v-deep tr.striped {
  background-color: #f9f9f9;
}

::v-deep .table {
  margin-bottom: 0;
}

.group-item {
  td:first-child {
    padding-left: 27px;
  }
}

.group {
  cursor: pointer;

  &:hover {
    background: #f5f5f5;
  }

  .fa {
    width: 14px;
    margin-left: 2px;
  }
}

.resource-name {
  display: flex;
  align-items: baseline;
  gap: 5px;

  svg {
    align-self: center;
  }

  .icon {
    width: 15px;
    text-align: center;
  }
}
</style>
