<template>
  <section>
    <div style="position:relative" :class="editing ? 'editing' : ''">
      <div class="clicable" style="position:absolute;right:5px;top:0px;">
        <span class="btn btn-xs" @click="edit(true)" v-if="!editing">
          <i class="fa fa-pencil"></i>
          <span> {{ $t("edit") }}</span>
        </span>
        <span class="btn btn-xs" @click="edit(false)" v-else>
          <i class="fa fa-close"></i>
        </span>
      </div>
      <div class="h4" :title="`${$t('contract')} #${contract && contract.id}`">
        {{ $t("current_contract") }}
      </div>
      <div class="h4">
        <div>
          <small>
            <b> {{ $t("start") }}: </b>
          </small>
          <small v-if="contract && contract.start_datetime">{{
            $dt.format(contract.start_datetime)
          }}</small>
        </div>
      </div>
    </div>
    <form class="form-horizontal" style="clear:both;">
      <div class="box-body">
        <div class="form-group">
          <label for="contract_name" class="col-sm-2 control-label">{{
            $t("name")
          }}</label>
          <div class="col-sm-10">
            <input
              type="text"
              ref="contract_name"
              id="contract_name"
              class="form-control"
              :placeholder="contractNamePlaceHolder"
              v-model="contract_name"
              v-bind:disabled="!editing || busy || disabled"
            />
          </div>
        </div>
        <div class="form-group">
          <label for="contract_description" class="col-sm-2 control-label">{{
            $t("description")
          }}</label>
          <div class="col-sm-10">
            <input
              type="text"
              class="form-control"
              ref="contract_description"
              v-bind:placeholder="$t('contract_description')"
              v-model="contract_description"
              v-bind:disabled="!editing || busy || disabled"
            />
          </div>
        </div>
      </div>
      <div
        class="box-footer text-center"
        style="background:transparent;margin-top:-20px;"
      >
        <span
          class="btn btn-primary btn-sm"
          :disabled="!editing || !isDirty || busy || disabled"
          @click="save"
        >
          <i
            :class="busy || disabled ? 'fa fa-refresh fa-spin' : 'fa fa-check'"
          ></i>
          <span>
            {{ $t("save") }}
          </span>
        </span>
      </div>
    </form>
  </section>
</template>

<script>
import ContractService from "@/services/contract.js";
export default {
  name: "FormContract",
  props: {
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      busy: false,
      contract_name: "",
      contract_description: "",
      editing: false,
      editing_name: false,
      editing_description: false
    };
  },
  computed: {
    user() {
      return this.$store.getters["user/loggedUser"] || null;
    },
    isDemo() {
      return (
        (this.user && this.user.contract && this.user.contract.is_demo) || false
      );
    },
    isRoot() {
      return (this.user && this.user.is_root) || false;
    },
    contract() {
      return this.$store.getters["user/contract"] || null;
    },
    contractNamePlaceHolder() {
      return (
        this.contract_name || `${this.$t("contract")} #${this?.contract?.id}`
      );
    },
    isNameDirty() {
      return (
        this.contract_name && this.contract_name != (this?.contract?.name || "")
      );
    },
    isDescriptionDirty() {
      return (
        this.contract_description &&
        this.contract_description != (this?.contract?.description || "")
      );
    },
    isDirty() {
      return this.isNameDirty || this.isDescriptionDirty;
    },
    payload() {
      let payload = {
        id: this.contract.id,
        etag: this.contract.etag,
        name: this.contract_name,
        description: this.contract_description
      };
      return payload;
    }
  },
  watch: {
    payload(n) {
      this.$emit("change", n);
    }
  },
  methods: {
    edit(option) {
      this.editing = option;
      if (option) {
        this.$nextTick(() => {
          let $i = this.$refs.contract_name;
          if ($i) {
            $i.selectionStart = $i.selectionEnd = 10000;
            $i.focus();
          }
        });
      } else {
        this.contract_name = this?.contract?.name || "";
        this.contract_description = this?.contract?.description || "";
      }
    },
    save() {
      if (this.busy) return;
      let payload = this.payload;
      this.busy = true;
      this.service.updateContract(payload).then((response) => {
        this.busy = false;
        if (response && typeof response == "object" && response.id) {
          this.$store.dispatch("user/configureUserContract"); // config contract
          this.$emit("save", response);
          this.editing = false;
        } else {
          this.$swal({
            title: this.$t("item_could_not_be_saved"),
            text: this.$t("invalid_data"),
            icon: "error"
          });
        }
      });
    },
    onCancel() {
      let self = this;
      if (self.busy) return;
      self.$emit("cancel");
    }
  },
  created() {
    this.service = new ContractService();
  },
  mounted() {
    this.contract_name = this.contract.name;
    this.contract_description = this.contract.description;
    if (!this.contract_name) {
      this.edit(true);
    }
  }
};
</script>

<style scoped>
.h4 {
  margin: 0;
  text-align: center;
}
.col-sm-2 {
  padding-right: 5px;
}
.editing {
  color: #2d6a8e;
}
</style>
