<template>
  <table class="table table-bordered table-striped">
    <tbody>
      <tr>
        <td><strong>{{ $tc("due_date") }}</strong></td>
        <td>{{ currentInvoiceDueDate }}</td>
      </tr>
      <tr>
        <td><strong>{{ $tc("invoice_payment_date") }}</strong></td>
        <td>{{ currentInvoicePaidAt }}</td>
      </tr>
      <tr>
        <td><strong>{{ $tc("invoice_status") }}</strong></td>
        <td>{{ currentInvoice.status }}</td>
      </tr>
      <tr>
        <td><strong>{{ $tc("value") }}</strong></td>
        <td>{{ $utils.formatMoney(currentInvoice.value_total) }}</td>
      </tr>
      <tr>
        <td><strong>{{ $tc("discount") }}</strong></td>
        <td>{{ $utils.formatMoney(currentInvoice.value_discount) }}</td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  name: "InvoiceTable",
  props: {
    currentInvoice: {
      type: Object,
      required: true
    }
  },
  computed: {
    currentInvoiceDueDate() {
      try {
        return this.$dt.format(this.currentInvoice.due_date).split(' ')[0];
      } catch {
        return "";
      }
    },
    currentInvoicePaidAt() {
      try {
        return this.$dt.format(this.currentInvoice.paid_at).split(' ')[0];
      } catch {
        return "";
      }
    }
  }
};
</script>