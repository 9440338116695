<template>
  <section>
    <div class="row">
      <div class="col-xs-6 col-md-6">
        <label for="filter_by">{{ $tc("filter_by") }}</label
        ><br />
        <Radio
          id="filter_by"
          :options="filterByOptions"
          v-model="itemFilter.dateType"
        />
      </div>
      <div class="col-xs-6 col-md-3">
        <label>{{ $tc("date_range") }}</label
        ><br />
        <DatetimeRangePicker
          :startRef="itemFilter.startDate"
          :endRef="itemFilter.endDate"
          :min="8760"
          :shortcuts="false"
          :allowFutureDate="true"
          :showRefreshIcon="false"
          @interval-changed="onIntervalChanged"
          class="datetime-range-picker"
        ></DatetimeRangePicker>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-6 col-md-6">
        <label for="invoice_status">{{ $tc("invoice_status") }}</label
        ><br />
        <Radio
          id="invoice_status"
          :options="invoiceStatusOptions"
          v-model="itemFilter.invoiceStatus"
        />
      </div>
      <div class="col-xs-6 col-md-6">
        <label>{{ $tc("value") }}</label>
        <div class="row">
          <div class="col-xs-4 col-md-1">{{ $tc("range_from") }}:</div>
          <div class="col-xs-8 col-md-4">
            <InputMoney
              id="min_value"
              v-model="itemFilter.minValue"
              :disabled="busy"
            />
          </div>
          <div class="col-xs-4 col-md-1">{{ $tc("range_to") }}:</div>
          <div class="col-xs-8 col-md-4">
            <InputMoney
              id="max_value"
              v-model="itemFilter.maxValue"
              :disabled="busy"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <Spin v-if="!dataLoaded" />
      <div class="col-xs-12" v-if="dataLoaded">
        <SearchableTable
          class="searchable-table"
          :items="itemsFiltered"
          :pagination="true"
          :searchEnabled="false"
          :maxResult="itemsFiltered.length"
          :fields="fields"
          :commands="commands"
          @command="onCommand"
        />
      </div>
    </div>

    <Modal
      :open="selectedTaxInvoice != null"
      @update:open="selectedTaxInvoice = $event ? selectedTaxInvoice : null"
      :title="$tc('tax_invoice') + ' #' + currentTaxInvoice.id"
      iconClass="fa fa-file-text-o"
      :footer="false"
    >
      <template #content>
        <TaxInvoiceTable :currentTaxInvoice="currentTaxInvoice" />
      </template>
    </Modal>

    <Modal
      :open="selectedInvoice != null"
      @update:open="selectedInvoice = $event ? selectedInvoice : null"
      :title="$tc('invoice') + ' #' + currentInvoice.id"
      iconClass="fa fa-money"
      :footer="false"
    >
      <template #content>
        <InvoiceTable :currentInvoice="currentInvoice" />
      </template>
    </Modal>
  </section>
</template>

<script>
import DashboardEditPickerBase from "@/components/registration/dashboard-edit-picker-base.vue";
import SearchableTable from "@/components/searchable-table.vue";
import DatetimeRangePicker from "@/components/datetime-range-picker.vue";
import InputMoney from "@/components/input-money.vue";
import Radio from "@/components/base/radio.vue";
import BillingService from "@/services/billing.js";
import Modal from "@/components/widgets/modal.vue";
import TaxInvoiceTable from "./tax-invoice-table.vue";
import InvoiceTable from "./invoice-table.vue";
import Spin from "@/components/spin";

export default {
  name: "UserBillingInvoicePicker",
  components: {
    SearchableTable,
    DatetimeRangePicker,
    InputMoney,
    Radio,
    Modal,
    TaxInvoiceTable,
    InvoiceTable,
    Spin
  },
  extends: DashboardEditPickerBase,
  props: {
    formPicker: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    let self = this;
    let cmds = {
      title: "loading",
      filterByOptions: [
        { value: "due_date", description: this.$tc("invoice_due_date") },
        { value: "paid_at", description: this.$tc("invoice_payment_date") }
      ],
      invoiceStatusOptions: [
        { value: "all", description: this.$tc("invoice_status_all") },
        { value: "pending", description: this.$tc("invoice_status_pending") },
        { value: "paid", description: this.$tc("invoice_status_paid") },
        {
          value: "cancelled",
          description: this.$tc("invoice_status_cancelled")
        }
      ],
      items: [],
      fields: [
        {
          name: "id",
          title: "id",
          visible: false
        },
        {
          name: "url",
          title: "url",
          visible: false
        },
        {
          name: "due_date",
          title: "invoice_due_date",
          format: (v) => {
            try {
              return self.$dt.format(v).split(" ")[0];
            } catch {
              return "";
            }
          }
        },
        {
          name: "paid_at",
          title: "invoice_payment_date",
          format: (v) => {
            try {
              return self.$dt.format(v).split(" ")[0];
            } catch {
              return "";
            }
          }
        },
        {
          name: "status",
          title: "invoice_status"
        },
        {
          name: "value_total",
          title: "value",
          style: { "text-align": "right" },
          format: (v) => {
            return self.$utils.formatMoney(v);
          }
        },
        {
          name: "value_discount",
          title: "discount",
          style: { "text-align": "right" },
          format: (v) => {
            return self.$utils.formatMoney(v);
          }
        }
      ],
      commands: [
        {
          name: "bank_slip",
          title(item) {
            if (item.status == self.statusEmitted) {
              return self.$t("bank_slip");
            } else {
              return self.$t("bank_slip_unavailable");
            }
          },
          icon: "glyphicon glyphicon-barcode",
          enabled(item) {
            return item.status == self.statusEmitted;
          }
        },
        {
          name: "invoice",
          title: "invoice",
          icon: "fa fa-money "
        },
        {
          name: "tax_invoice",
          title(item) {
            if (item.tax_invoice_id) {
              return self.$t("tax_invoice");
            } else {
              return self.$t("tax_invoice_unavailable");
            }
          },
          icon: "fa fa-file-text-o ",
          enabled(item) {
            return item.tax_invoice_id ? true : false;
          }
        },
        {
          name: "resend_invoice_email",
          title(item) {
            if (item.status == self.statusEmitted) {
              return self.$t("resend_invoice_email");
            } else {
              return self.$t("resend_invoice_email_unavailable");
            }
          },
          icon: "fa fa-envelope-o",
          enabled(item) {
            return item.status == self.statusEmitted;
          }
        }
      ],
      itemFilter: {
        startDate: moment().add(-1, "year"),
        endDate: moment(),
        dateType: "due_date",
        invoiceStatus: "all",
        minValue: null,
        maxValue: null
      },
      dataLoaded: false,
      selectedInvoice: null,
      selectedTaxInvoice: null
    };
    return cmds;
  },
  computed: {
    statusEmitted() {
      return "Emitida";
    },
    convertedStatus() {
      switch (this.itemFilter.invoiceStatus) {
        case "pending":
          return "Emitida";
        case "paid":
          return "Liquidada";
        case "cancelled":
          return "Cancelada";
        default:
          return null;
      }
    },
    currentInvoice() {
      return this.selectedInvoice != null ? this.selectedInvoice : {};
    },
    currentTaxInvoice() {
      return this.selectedTaxInvoice != null ? this.selectedTaxInvoice : {};
    },
    itemsFiltered() {
      if (!Array.isArray(this.items)) return [];

      let dateField = this.itemFilter.dateType;
      let startDate = this.itemFilter.startDate;
      let endDate = this.itemFilter.endDate;
      let minValue = this.itemFilter.minValue;
      let maxValue = this.itemFilter.maxValue;
      let convertedStatus = this.convertedStatus;

      return this.items.filter((item) => {
        // Period
        let momentDate = item[dateField] ? moment(item[dateField]) : null;

        if (!momentDate) return false;
        if (momentDate && momentDate < startDate && momentDate > endDate)
          return false;

        // Status
        if (convertedStatus && item.status != convertedStatus) return false;

        // Value Range
        if (typeof minValue == "number" && item.value_total < minValue)
          return false;
        if (
          typeof maxValue == "number" &&
          maxValue >= minValue &&
          item.value_total > maxValue
        )
          return false;

        return true;
      });
    }
  },
  watch: {
    busy(n) {
      this.$emit("loading", n);
    }
  },
  methods: {
    bank_slip(event) {
      this.$utils.navigate(event.target.url, { new_tab: true });
    },
    invoice(event) {
      this.selectedInvoice = event.target;
    },
    tax_invoice(event) {
      if (!event.target.tax_invoice_id) return;
      let self = this;
      this.service
        .tax_invoices(null, event.target.tax_invoice_id)
        .then((result) => {
          if (result) {
            this.selectedTaxInvoice = result;
          } else {
            self.$swal({
              title: self.$t("error"),
              text: self.$t("not_found"),
              icon: "error"
            });
          }
        });
    },
    resend_invoice_email(event) {
      let self = this;
      this.$utils
        .confirm(this, this.$tc("invoice_resend_email_message"))
        .then((result) => {
          if (!result) return;
          self.busy = true;
          self.service.resend_invoice_email(event.target.id).then((result) => {
            self.busy = false;
            if (result) {
              self.$swal({
                title: self.$tc("success"),
                text: self.$tc("invoice_resend_email_success"),
                icon: "success"
              });
            }
          });
        });
    },
    fetchItems() {
      this.service.invoices().then((result) => {
        this.$set(this, "items", result.reverse());
        this.$set(this, "dataLoaded", true);
      });
    },
    onIntervalChanged(e) {
      this.itemFilter.startDate = e.startDate;
      this.itemFilter.endDate = e.endDate;
    }
  },
  beforeCreate() {
    this.service = new BillingService();
  },
  mounted() {}
};
</script>

<style scoped>
.searchable-table {
  margin-top: 30px;
}
.datetime-range-picker {
  max-width: 100%;
}
</style>
