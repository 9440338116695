<template>
  <div :class="['box', themeClass, { 'box-solid': solid }]">
    <div
      v-if="$slots.title || title || $slots.tools"
      :class="['box-header', { collapsable, 'with-border': headerBorder }]"
      @click.self="toggle"
    >
      <h3 class="box-title">
        <slot name="title">{{ title }}</slot>
      </h3>
      <div class="box-tools pull-right">
        <slot name="tools"></slot>
        <button
          type="button"
          class="btn btn-box-tool"
          data-widget="collapse"
          v-if="collapsable"
        >
          <i class="fa fa-minus"></i>
        </button>
      </div>
    </div>
    <slot name="before-body"></slot>
    <div class="box-body">
      <slot></slot>
    </div>
    <slot name="after-body"></slot>
    <transition name="footer">
      <div class="box-footer" v-if="$slots.footer">
        <slot name="footer"></slot>
      </div>
    </transition>
    <slot name="table"></slot>
    <div class="overlay" v-if="loading">
      <i class="fa fa-refresh fa-spin"></i>
    </div>
  </div>
</template>

<script>
export default {
  name: "Box",
  props: {
    title: {
      type: String,
      required: false,
      default: ""
    },
    solid: {
      type: Boolean,
      required: false,
      default: false
    },
    type: {
      type: String,
      required: false,
      default: "default",
      validator(value) {
        return (
          [
            "default",
            "primary",
            "info",
            "warning",
            "success",
            "danger"
          ].indexOf(value) >= 0
        );
      }
    },
    loading: {
      type: Boolean,
      required: false,
      default: false
    },
    collapsable: {
      type: Boolean,
      required: false,
      default: false
    },
    collapsed: {
      type: Boolean,
      required: false,
      default: false
    },
    headerBorder: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    themeClass() {
      return `box-${this.type}`;
    }
  },
  methods: {
    toggle() {
      if (this.collapsable) $(this.$el).boxWidget("toggle");
    }
  },
  mounted() {
    $(this.$el).boxWidget({
      animationSpeed: 300
    });
    if (this.collapsed) {
      $(this.$el).boxWidget("collapse");
    }
  }
};
</script>

<style scoped>
.box-header.collapsable {
  cursor: pointer;
}

@media (max-width: 767px) {
  .box-footer >>> .btn {
    padding-inline: 7px;
  }
}
</style>
